import './Question.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
  question?: string;
}

const Question: React.FC<Props> = (props) => {
  const { question, children } = props;

  return (
    <>
      <div className="Question">{question}</div>
      <div className="Question-answer">{children}</div>
    </>
  );
};

export default Question;
