import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Publications, {
  Props as PublicationsProps
} from 'components/in-print/Publications';
import Question from 'components/in-print/Question';

interface Props {
  data: PublicationsProps & {
    authorImage: Core.ImageAttributes;
    authorImageBook: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { publications, authorImage, authorImageBook } = props.data;

  return (
    <Layout departmentSlug="in-print" title="In Print">
      <Core.ContentWrapper>
        <Core.ContentSection id="q-and-a-with-author">
          <Core.DepartmentLabel>In Print</Core.DepartmentLabel>
          <Core.Flag>Q&amp;A with Author</Core.Flag>
          <Core.PrimaryHeading
            subHeading={<em>State of Repression: Iraq Under Saddam Hussein</em>}
          >
            Lisa Blaydes &rsquo;98
          </Core.PrimaryHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={authorImageBook}
                alt="State of Repression: Iraq Under Saddam Hussein"
                contentSectionPlacement="marginalia"
                caption={
                  <>
                    <span>(Princeton University Press)</span>
                    <br />
                    <a
                      href="https://www.amazon.com/State-Repression-under-Saddam-Hussein/dp/069118027X"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Buy on Amazon
                    </a>
                  </>
                }
              />
              <Core.Caption>
                Lisa Blaydes ’98 is a professor of political science at Stanford
                University and a senior fellow at the Freeman Spogli Institute
                for International Studies.
              </Core.Caption>
            </>
          }
        >
          <Question question="Your book relies on documents captured during the invasions of Iraq in 1991 and 2003. Which archives proved most useful?">
            The documents I used most were from the Iraqi Memory Foundation at
            the Hoover Institution. There are 11 million papers there. It’s the
            only sort of collection like this for a Middle Eastern autocracy. I
            also used documents from the now-defunct Conflict Records Research
            Center at the National Defense University—which are transcripts of
            Saddam Hussein’s cabinet-level meetings—and the Iraqi Secret Police
            Files at the University of Colorado Boulder. Together, these
            documents provide a window into a society and a regime struggling
            for survival. The regime is on the rocks, trying to avoid both coups
            and popular revolts. In society, people are just struggling to
            survive.
          </Question>

          <Question question="What other things do these documents tell us about Iraq in this era?">
            They show what sort of information the regime was interested in
            collecting. They were always looking for more information to make
            their society more legible and to better control it. And they faced
            real constraints.
          </Question>
          <Core.FullWidthImage
            image={authorImage}
            alt="Lisa Blaydes"
            caption={<em>John Davis Photography</em>}
          />
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Blockquote color="beige">
              These documents revealed countless examples of Iraqis trying to
              find small and large ways to rebel against the system.
            </Core.Blockquote>
          }
        >
          <Question question="A key concept in State of Repression is a repressive regime’s ability to successfully “read” its people—i.e., the society’s “legibility.” In what ways did Iraq’s “illegibility” play out for Saddam Hussein’s regime?">
            When they [regime leaders] were in a tough position and unable to
            figure out who was for them and who was against them, they engaged
            in forms of group or collective punishment that had tremendous
            negative implications and created new identities in some parts of
            society—identities that were separate from what the state wanted
            them to have.
          </Question>
          <Question question="What struck you most as you researched the book?">
            <p>
              These documents revealed countless examples of Iraqis trying to
              find small and large ways to rebel against the system. There were
              refusals to identify as a member of the Baath Party and assert
              their political independence. Iraqis also spread negative rumors
              that challenged the regime’s monopoly on information. Many of
              these rumors were quite subversive, pointing a finger of blame at
              Saddam Hussein or the regime itself.
            </p>
            <p>
              Iraqis embraced the forms of human agency they had available to
              them to express their dissatisfaction with a cruel and repressive
              regime. It was important for me to see that there are political
              behaviors we can observe, even in the most repressive
              dictatorships.
            </p>
          </Question>
          <Core.SignOff>Richard Byrne</Core.SignOff>
          <Core.Share id="q-and-a-with-author" />
        </Core.ContentSection>
        <Core.HorizontalRule />

        <Core.Flag>Published</Core.Flag>
        <Publications publications={publications} />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    publications: allFile(
      filter: {
        relativePath: { regex: $directory }
        name: { eq: "in-print-publications" }
      }
    ) {
      ...Publications
    }
    authorImage: file(
      relativePath: { regex: $directory }
      name: { eq: "in-print-lisa-blaydes" }
    ) {
      ...ContentWidthImage
    }
    authorImageBook: file(
      relativePath: { regex: $directory }
      name: { eq: "in-print-lisa-blaydes-book" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default DepartmentContent;
